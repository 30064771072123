.hot{
  position: relative;
  background: url(../../assets/img/prize-banner-bg.png) no-repeat top center;
  background-size: contain;
  height: 100%;
  width: 100%;
  .title{
    padding-top: 13px;
    font-family: AlimamaShuHeiTi-Bold;
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0px;

    color: #FFFFFF;
    margin-bottom: 32px;
  }
  .join{
    width: 200px;
    text-align: left;
    h4{
      background: linear-gradient(270deg, rgba(226, 254, 87, 0) 13%, rgba(226, 254, 87, 0.6532) 102%);
      font-family: AlimamaShuHeiTi-Bold;
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      letter-spacing: 0px;
      color: #FFFFFF;
      padding-left: 23px;
      padding-top: 2px;
      padding-bottom: 6px;
      margin-bottom: 10px;
    }
    p{
      font-family: DOUYUFont;
      font-size: 20px;
      font-weight: normal;
      line-height: 24px;
      letter-spacing: 0px;
      color: #FFFFFF;
      padding-left: 23px;
    }
    button{
      font-family: 苹方-简;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0px;
      background: #C9F44C;
      color: #000000;
      outline: none;
      border: none;
      padding: 8px 7px;
      border-radius: 18px;
      margin-left: 23px;
      margin-top: 10px;
    }
  }
  .scrollable-container {
    margin-top: 40px;
    width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    padding-bottom: 10px;
    .scrollable-content {
      display: flex;
      flex-direction: row;
      transition: transform 0.3s ease; /* 可以添加过渡效果 */
      .item {
        flex: 0 0 auto; /* 让每个项目不拉伸，但能够自动调整大小 */
        margin-right: 10px; /* 间距为 14px - 根据需求调整 */
        background: rgba(255, 255, 255, 0.9891);
        padding: 10px 15px;
        border-radius: 16px;
        backdrop-filter: blur(27.2px);
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.0558),inset 0px 1px 3px 0px rgba(255, 255, 255, 0.4047);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &.active{
          border: 3px solid #C9F44C;
        }
        h3{
          font-family: 苹方-简;
          font-size: 13px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0px;
          color: #000000;
        }
        .content{
          margin-top: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          .img{
            width: 46px;
          }
          .text{
            margin-left: 4px;
            text-align: left;
            font-family: 苹方-简;
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
  }
}