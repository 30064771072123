.star{
  background: url(../../assets/img/contain-bg.png) no-repeat top center;
  background-size: cover;
  height: 100%;
  padding: 13px 20px;
  .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2{
      font-family: Akrobat-ExtraBold;
      font-size: 22px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0px;

      color: #2B2B2B;
    }
  }
  .invite{
    margin-top: 16px;
    .item{
      padding: 16px 8px 8px;
      text-align: left;
      h3{
        font-family: Akrobat-ExtraBold;
        font-size: 26px;
        font-weight: normal;
        line-height: 31px;
        letter-spacing: 0px;
        color: #000000;
        padding-left: 12px;
      }
      p{
        font-family: 苹方-简;
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
        letter-spacing: 0px;
        margin-bottom: 16px;
        color: #000000;
        padding-left: 12px;
      }
      button{
        padding: 0;
        border: none;
        outline: none;
        background: transparent;
        width: 100%;
        
      }
      &.item1{
        border-radius: 16px;
        background: url(../../assets/img/invite-bg.png) no-repeat center;
        background-size: cover;
      }
      &.item2{
        h3{
          color: #E2FE57;
        }
        p{
          color: #FFFFFF;
        }
        border-radius: 16px;
        background: url(../../assets/img/winner-bg.png) no-repeat center;
        background-size: cover;
      }
    }
  }
  .Cybertruck{
    border-radius: 16px;
    background: url(../../assets/img/Beast-bg.png) no-repeat center;
    background-size: cover;
    background-color: radial-gradient(49% 49% at 50% 50%, #E2FE57 0%, rgba(226, 254, 87, 0) 100%);
    padding: 18px 0 10px;
    margin-top: 16px;
    margin-bottom: 7px;
    h2{
      font-family: Akrobat-ExtraBold;
      font-size: 15px;
      font-weight: normal;
      line-height: 18px;
      letter-spacing: 0px;

      color: #FFFFFF;

      text-shadow: 0px 2px 8px #000000;
    }
    h3{

      font-family: Akrobat-ExtraBold;
      font-size: 26px;
      font-weight: normal;
      line-height: 31px;
      letter-spacing: 0px;

      color: #FFFFFF;

      text-shadow: 0px 2px 8px #000000;
    }
    p{

      font-family: Akrobat-ExtraBold;
      font-size: 34px;
      font-weight: normal;
      line-height: 32px;
      letter-spacing: 0px;

      color: #E2FE57;

      text-shadow: 2px 3px 0px #4F5DFA;
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6px;
      button{
        width: 139px;
        border-radius: 18px;
        opacity: 1;
        background: url(../../assets/img/join-btn-bg.png) no-repeat center;
        background-size: 100% 100%;
        border: none;
        outline: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
        span{
          font-family: 苹方-简;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0px;
          color: #FFFFFF;
        }
        i{
          font-family: 苹方-简;
          font-size: 8px;
          font-weight: 500;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0px;
          font-style: normal;
          color: #E2FE57;
        }
      }
    }
  }
  .list{
    padding-top: 17px;
    .list-title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
      .left{
        flex: 1;
        display: flex;
        align-items: center;
        h3{
          font-family: AlimamaShuHeiTi-Bold;
          font-size: 20px;
          font-weight: normal;
          line-height: 24px;
          letter-spacing: 0px;

          color: #000000;
          margin-right: 10px;
        }
        span{
          font-family: 苹方-简;
          font-size: 13px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;

          color: rgba(0, 0, 0, 0.6);
        }
      }
      .more{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 苹方-简;
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        letter-spacing: 0px;

        color: #666666;
        img{
          height: 7px;
          margin-left: 6px;
        }
      }
    }
    .list-view{
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 分为三列 */
      gap: 14px; /* 间距为 14px */
      .item{
        h3{
          font-family: Akrobat-ExtraBold;
          font-size: 15px;
          font-weight: normal;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          padding: 3px 6px;

          color: #000000;
        }
      }
      &.picks{
        grid-template-columns: repeat(2, 1fr); /* 分为三列 */
      }
    }
  }
}