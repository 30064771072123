.MysteryBox{
  .list-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    .left{
      flex: 1;
      display: flex;
      align-items: center;
      h3{
        font-family: AlimamaShuHeiTi-Bold;
        font-size: 20px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 0px;

        color: #000000;
        margin-right: 10px;
      }
      span{
        font-family: 苹方-简;
        font-size: 13px;
        font-weight: normal;
        line-height: 16px;
        letter-spacing: 0px;

        color: rgba(0, 0, 0, 0.6);
      }
    }
    .more{
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 苹方-简;
      font-size: 12px;
      font-weight: normal;
      line-height: 12px;
      letter-spacing: 0px;

      color: #666666;
      img{
        height: 7px;
        margin-left: 6px;
      }
    }
  }
  padding: 12px 20px;
  box-sizing: border-box;
  .title{
    font-family: AlimamaShuHeiTi-Bold;
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    position: relative;
    .return{
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .banner{
    margin-top: 21px;
    padding: 13px 20px;
    background: url(../../assets/mysterybox/play-bg.png) no-repeat top center;
    background-size: 100% 100%;
    text-align: left;
    .title{
      font-family: Akrobat-ExtraBold;
      font-size: 26px;
      font-weight: normal;
      line-height: 31px;
      color: #000000;
    }
    p{
      font-family: 苹方-简;
      font-size: 11px;
      font-weight: normal;
      line-height: 13px;
      color: #000000;
      margin-bottom: 21px;
    }
    button{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      outline: none;
      padding: 2px 3px;
      border-radius: 22px;
      background: #FFFFFF;
      span{
        font-family: 苹方-简;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: #000000;
        margin-left: 10px;
        margin-right: 16px;
      }
    }
  }
  .enties{
    margin-top: 16px;
    .list{
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 分为三列 */
      gap: 14px; /* 间距为 14px */
      .item{
        .name{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .price{
          font-family: Akrobat-ExtraBold;
          font-size: 18px;
          font-weight: normal;
          line-height: 22px;
          text-align: center;
          color: #000000;
        }
      }
    }
  }
  .projects{
    margin-top: 16px;
    padding-bottom: 30px;
    .list{
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 分为三列 */
      gap: 14px; 
      .item{
        .box{
          border-radius: 16px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, #DFDFDF 100%);
          box-shadow: inset 0px 1px 3px 0px rgba(255, 255, 255, 0.4047);
          padding: 20px;
          position: relative;
          .label{
            position: absolute;
            left: 0;
            top: 4px;
          }
          .countDown{
            font-family: 苹方-简;
            font-size: 10px;
            font-weight: normal;
            line-height: 12px;
            color: #333333;
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
          }
        }
        .name{
          margin-top: 6px;
          font-family: 苹方-简;
          font-size: 12px;
          font-weight: normal;
          line-height: 14px;
          text-align: center;
          color: #000000;
        }
        .price{
          font-family: Akrobat-ExtraBold;
          font-size: 18px;
          font-weight: normal;
          line-height: 22px;
          text-align: center;
          color: #000000;
        }
        button{
          border: none;
          outline: none;
          font-family: 苹方-简;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
          text-align: center;
          color: #333333;
          width: 100%;
          border-radius: 16px;
          background: #C9F44C;
          padding: 7px 0;
          margin-top: 12px;
        }
      }
    }
  }

}