.CompetationTab{
  .title{
    .MuiTab-root{
      padding: 0;
      margin-right: 18px;
      font-family: AlimamaShuHeiTi-Bold;
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 0.501);
      text-transform: none;
      min-width: auto;
      position: relative;
      transition: all linear .5s;
      &.Mui-selected{
        color: #000000;
        &::after{
          border: 3px solid #C9F44C;
        }
      }
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 14px;
        height: 0px;
        border: 3px solid #caf44c00;
        transition: all linear .5s;
        border-radius: 10px;
      }
      
    }
    .MuiTabs-indicator{
      display: none;
    }
  
  
  }
  .MuiTabPanel-root{
    padding: 0;
  }
  .labs-list{
    display: flex;
    align-items: center;
    // margin-top: 12px;
    margin-bottom: 12px;
    .item{
      border-radius: 16px;
      background: rgba(176, 176, 176, 0.1);
      box-shadow: inset 0px 1px 3px 0px rgba(255, 255, 255, 0.4047);
      padding: 10px;
      font-family: 苹方-简;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      text-align: center;
      letter-spacing: 0px;
      margin-right: 10px;

      color: #333333;
      &.active{
        background-color: #C9F44C;
      }
    }
  }

  .tableBox{
    margin-top: 12px;
    .css-15wwp11-MuiTableHead-root{
      margin-bottom: 4px;
      // border-radius: 16px;
     
    }
    .header-content{
      background: rgba(176, 176, 176, 0.1);
      box-shadow: inset 0px 1px 3px 0px rgba(255, 255, 255, 0.4047);
      padding: 6px 16px;
      font-family: 苹方-简;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0px;
      color: #333333;
      &.br-l-50{
        border-radius: 20px 0 0 20px;
      }
      &.br-r-50{
        border-radius: 0 20px 20px 0;
              }
    }
    .MuiTableRow-root,.MuiTableCell-root{
      border: none;
      font-family: 苹方-简;
      font-size: 12px;
      font-weight: normal;
      line-height: 14px;
      letter-spacing: 0px;
      color: #333333;
    }
    .MuiPaper-root{
      box-shadow: none;
    }
    .MuiTableCell-body{
      .text{
        font-family: 苹方-简;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0px;
        color: #333333;
      }
    }
    .MuiTableHead-root{
      .MuiTableCell-head{
        padding: 0;
      }
    }
    .css-dsuxgy-MuiTableCell-root{
      padding: 6px 5px;
    }
    .join{
      border-radius: 16px;
      background: #C9F44C;
      font-family: 苹方-简;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      color: #333333;
      border: none;
      outline: none;
      padding: 7px 18px;
    }
  }
 

}

