.leaderboard{
  background: url(../../assets/img/contain-bg.png) no-repeat top center;
  background-size: cover;
  height: 100%;
  padding: 13px 20px;
  box-sizing: border-box;
  .title{
    font-family: AlimamaShuHeiTi-Bold;
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
  }
  .directory{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 分为三列 */
    gap: 15px; /* 间距为 14px */
    margin-top: 20px;
    .item{
      padding: 10px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      .text{
        margin-left: 6px;
        text-align: left;
        h3{
          font-family: AlimamaShuHeiTi-Bold;
          font-size: 16px;
          font-weight: normal;
          line-height: 19px;
          letter-spacing: 0px;
          color: #000000;
        }
        p{
          font-family: 苹方-简;
          font-size: 12px;
          font-weight: normal;
          line-height: 14px;
          letter-spacing: 0px;
          color: #333333;
        }
      }
      &.user{
        background: #C9F44C;
      }
      &.store{
        background: linear-gradient(288deg, rgba(0, 0, 0, 0.4131) -89%, #303030 68%);
        .text{
          h3,p{
            color: #C9F44C;
          }
        }
      }
    }
  }
}