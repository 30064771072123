.hotTab{
  .hotTabTitle{
    .MuiTab-root{
      padding: 0;
      margin-right: 18px;
      font-family: AlimamaShuHeiTi-Bold;
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 0.5);
      min-width: auto;
      position: relative;
      transition: all linear .5s;
      &.Mui-selected{
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        &::after{
          border: 3px solid #C9F44C;
        }
      }
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 14px;
        height: 0px;
        border: 3px solid #caf44c00;
        transition: all linear .5s;
        border-radius: 10px;
      }
      
    }
    .MuiTabs-indicator{
      display: none;
    }
  
  
  }
  .list-view{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 分为三列 */
    gap: 14px; /* 间距为 14px */
    .item{
      border-radius: 16px;
      background: rgba(176, 176, 176, 0.1);
      box-shadow: inset 0px 1px 3px 0px rgba(255, 255, 255, 0.4047);
      padding: 2px;
      img{
        border-radius: 16px 16px 4px 4px;
        display: block;
      }
      .content{
        padding: 6px;
        text-align: left;
        h3{
          font-family: Akrobat-ExtraBold;
          font-size: 15px;
          font-weight: normal;
          line-height: 18px;
          letter-spacing: 0px;
          color: #000000;
        }
        .count{
          margin-top: 4px;
          p{
            font-family: 苹方-简;
            font-size: 8px;
            font-weight: 500;
            line-height: 10px;
            letter-spacing: 0px;
            color: #666666;
            margin-bottom: 2px;
          }
          .MuiLinearProgress-root{
            background: rgba(0, 0, 0, 0.9868);
            height: 5px;
            border-radius: 3px;
          }
          .MuiLinearProgress-bar{
            background: #B7E82C;
            border-radius: 3px;
          }
        }
        .btngroup{
          display: flex;
          align-items: center;
          justify-content: space-between;
          /* Join & Win */
          font-family: 苹方-简;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -2px;	
          color: #000000;
          margin-top: 9px;
          button{
            border: none;
            outline: none;
            padding: 7px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .join{
            width: auto;
            border-radius: 14px;
            opacity: 1;
            background: #C9F44C;
          }
          .add{
            border-radius: 14px;
            background: #000000;
            padding: 7px 15px;
            color: #E2FE57;
            img{
              margin-right: 2px;
            }
          }
        }
      }
    }
  }

}

