.my{
  background: url(../../assets/img/contain-bg.png) no-repeat top center;
  background-size: cover;
  height: 100%;
  padding: 30px 20px;
  padding-top: 38px;
  box-sizing: border-box;
  .info{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
    .img{
      width: 48px;
    }
    .name{
      padding: 0 12px;
      font-family: AlimamaShuHeiTi-Bold;
      font-size: 18px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 0px;
      color: #000000;
      text-align: left;
      .name-box{
        display: flex;
        align-items: center;
        div{
          background: #EBF1FF;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 苹方-简;
          font-size: 10px;
          font-weight: normal;
          line-height: 12px;
          letter-spacing: 0px;
          padding: 2px 5px;
          border-radius: 8px;
          margin-left: 8px;
          color: #7681FF;
          span{
            padding-left: 2px;
            padding-right: 4px;
          }
        }
      }
    }
    p{
      margin-top: 6px;
      font-family: 苹方-简;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0px;
      color: #666666;
    }
  }
  .wallet{
    margin-top: 25px;
    padding: 12px;
    background: #E2FE57;
    border-radius: 16px;
    background: url(../../assets/img/wallet-bg.png) no-repeat top center;
    background-size: cover;
    .title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 4px;
      box-sizing: border-box;
      .name{
        font-family: 苹方-简;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        color: #333333;
        display: flex;
        align-items: center;
      }
    }
    .count{
      font-family: Akrobat-ExtraBold;
      font-size: 32px;
      font-weight: normal;
      line-height: 38px;
      letter-spacing: 0px;
      color: #000000;
      margin-top: 9px;
      margin-bottom: 14px;
      text-align: left;
      padding-left: 4px;
      box-sizing: border-box;
    }
    .list{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 18px;
      background-color: #fff;
      border-radius: 8px;
      .num{
        font-family: DIN Alternate;
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0px;
        color: #333333;
      }
      .text{
        font-family: 苹方-简;
        margin-top: 4px;
        font-size: 12px;
        font-weight: normal;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0px;
        color: #333333;
      }
    }
  }
  .orders{
    padding: 20px 23px 24px;
    border-radius: 16px;
    background: linear-gradient(288deg, rgba(0, 0, 0, 0.4131) -89%, #303030 68%);
    margin-top: 6px;
    .title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 4px;
      box-sizing: border-box;
      .name{
        font-family: 苹方-简;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        color: #ffffff;
        display: flex;
        align-items: center;
      }
      .arrow{
        font-family: 苹方-简;
        font-size: 10px;
        font-weight: normal;
        line-height: 12px;
        text-align: right;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        img{
          margin-left: 4px;
        }
      }
    }
    .list{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 22px;
      .num{
        font-family: DIN Alternate;
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0px;
        color: #333333;
      }
      .text{
        font-family: 苹方-简;
        font-size: 12px;
        font-weight: normal;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-top: 6px;
      }
    }
  }
  .settled{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 分为三列 */
    gap: 15px; /* 间距为 14px */
    margin-top: 14px;
    .item{
      border-radius: 16px;
      background: rgba(176, 176, 176, 0.1);
      box-shadow: inset 0px 1px 3px 0px rgba(255, 255, 255, 0.4047);
      padding: 6px 10px 10px;
      padding-left: 0;
      .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 苹方-简;
        font-size: 10px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0px;
        color: #333333;
      }
      p{
        font-family: 苹方-简;
        font-size: 9px;
        font-weight: normal;
        line-height: 11px;
        text-align: center;
        letter-spacing: 0px;
        margin-top: 5px;
        color: #333333;
      }
    }
  }
  .Function{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 分为三列 */
    gap: 25px; /* 间距为 14px */
    margin-top: 24px;
    padding-bottom: 60px;
    .item{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p{
        font-family: 苹方-简;
        font-size: 12px;
        font-weight: normal;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0px;
        color: #333333;
      }
    }
  }
}