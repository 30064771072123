.Sellers{
  .list-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    .left{
      flex: 1;
      display: flex;
      align-items: center;
      h3{
        font-family: AlimamaShuHeiTi-Bold;
        font-size: 20px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 0px;

        color: #000000;
        margin-right: 10px;
      }
      span{
        font-family: 苹方-简;
        font-size: 13px;
        font-weight: normal;
        line-height: 16px;
        letter-spacing: 0px;

        color: rgba(0, 0, 0, 0.6);
      }
    }
    .more{
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 苹方-简;
      font-size: 12px;
      font-weight: normal;
      line-height: 12px;
      letter-spacing: 0px;

      color: #666666;
      img{
        height: 7px;
        margin-left: 6px;
      }
    }
  }
  padding: 13px 20px;
  box-sizing: border-box;
  .title{
    font-family: AlimamaShuHeiTi-Bold;
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    position: relative;
    .return{
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
    .explain{
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  .banner{
    margin-top: 21px;
    border-radius: 16px;
    background: #C9F44C;
    padding: 10px;
  }
}