.Crowdfunding{
  .list-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    .left{
      flex: 1;
      display: flex;
      align-items: center;
      h3{
        font-family: AlimamaShuHeiTi-Bold;
        font-size: 20px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 0px;

        color: #000000;
        margin-right: 10px;
      }
      span{
        font-family: 苹方-简;
        font-size: 13px;
        font-weight: normal;
        line-height: 16px;
        letter-spacing: 0px;

        color: rgba(0, 0, 0, 0.6);
      }
    }
    .more{
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 苹方-简;
      font-size: 12px;
      font-weight: normal;
      line-height: 12px;
      letter-spacing: 0px;

      color: #666666;
      img{
        height: 7px;
        margin-left: 6px;
      }
    }
  }
  padding: 12px 20px;
  box-sizing: border-box;
  .title{
    font-family: AlimamaShuHeiTi-Bold;
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    position: relative;
    .return{
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .banner{
    margin-top: 21px;
    padding: 13px 20px;
    background: url(../../assets/Crowdfunding/banner-bg.png) no-repeat top center;
    background-size: 100% 100%;
    text-align: left;
    .title{
      font-family: Akrobat-ExtraBold;
      font-size: 26px;
      font-weight: normal;
      line-height: 31px;
      color: #000000;
      text-align: left;
    }
    p{
      font-family: 苹方-简;
      font-size: 11px;
      font-weight: normal;
      line-height: 13px;
      color: #000000;
      margin-bottom: 21px;
    }
    button{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      outline: none;
      padding: 2px 3px;
      border-radius: 22px;
      background: #FFFFFF;
      span{
        font-family: 苹方-简;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: #000000;
        margin-left: 10px;
        margin-right: 16px;
      }
    }
  }
  .video-banner{
    margin-top: 16px;
    text-align: left;
    .video{
      margin-bottom: 16px;
      background: url(../../assets/Crowdfunding/video-banner-bg.png) no-repeat top center;
      background-size: 100% 100%;
      height: 136px;
      position: relative;
      border-radius: 16px;
      .play{
        position: absolute;
        right: 14px;
        bottom: 14px;
        width: 34px;
        height: 34px;
      }
    }
    .list-title{
      margin-bottom: 8px;
      .more{
        img{
          height: 12px;
          margin-right: 3px;
        }
      }
    }
    .slog{
      font-family: 苹方-简;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      color: #333333;
    }
    .progress{
      margin-top: 30px;
      .MuiLinearProgress-root{
        background: rgba(0, 0, 0, 0.9868);
        height: 8px;
        border-radius: 5px;
      }
      .MuiLinearProgress-bar{
        background: #B7E82C;
        border-radius: 5px;
      }
      .progress-count{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        >div{
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          &:last-child{
            align-items: flex-end;
          }
          .num{
            font-family: Akrobat-ExtraBold;
            font-size: 15px;
            font-weight: normal;
            line-height: 18px;
            letter-spacing: 0px;
            color: #000000;
            span{
              font-family: 苹方-简;
              font-size: 10px;
              font-weight: normal;
              line-height: 12px;
              color: #333333;
            }
          }
          p{
            font-family: 苹方-简;
            font-size: 10px;
            font-weight: normal;
            line-height: 12px;
            letter-spacing: 0px;
            color: #333333;
          }
        }
      }
      .support-btn{
        border-radius: 16px;
        background: #C9F44C;
        font-family: 苹方-简;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        color: #000000;
        padding: 10px 0;
        width: 100%;
        margin-top: 25px;
      }
    }
  }
  .Picks{
    margin: 0 -20px;
    margin-top: 16px;
    box-sizing: border-box;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 12px 20px;
    background: rgba(176, 176, 176, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(255, 255, 255, 0.4047);
    .list{
      display: grid;
      grid-template-columns: 1fr 1fr; /* 两列等宽 */
      gap: 10px; /* 间距为 14px */
      .item{
        display: grid;
        grid-template-rows: auto auto auto; /* 图片、标题、描述占据各自的行 */
        border-radius: 16px;
        background: rgba(176, 176, 176, 0.1);
        box-shadow: inset 0px 1px 3px 0px rgba(255, 255, 255, 0.4047);
        padding: 2px;
        text-align: left;
        img{
          border-radius: 16px 16px 4px 4px;
          display: block;
        }
       
        h3{
          font-family: 苹方-简;
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          color: #000000;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 6px;
        }
        p{
          font-family: 苹方-简;
          font-size: 12px;
          font-weight: normal;
          line-height: 14px;
          color: #333333;
          margin: 2px 6px;
        }
       
      }
    }
  }

}