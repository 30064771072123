.Competation{
  // background: url(../../assets/img/contain-bg.png) no-repeat top center;
  // background-size: cover;
  // height: 100%;
  // padding: 30px 20px;
  // box-sizing: border-box;
  .banner{
    width: 100%;
    position: relative;
    .callback{
      position: absolute;
      top: 26px;
      left: 20px;
      width: 36px;
      z-index: 99;
    }
    .slick-list{
      width: 100%;
      .slick-slide{
        width: 100%;
        background-color: rgb(0, 0, 0);
      }
    }
    .slick-dots{
      bottom: 38px;
      li {
        width: auto;
        height: auto;
        margin: 0 3px;
        button{
          width: auto;
          height: auto;
          &:before{
            content: '';
            opacity: 1;
            width: 6px;
            height: 6px;
            border-radius: 12px;
            background-color: rgba(255, 255, 255, 0.5529);
            transform: translateX(-50%);
            left: 50%;
          
          }
        }
      }
      li.slick-active  button{
        width: 14px;
      }
      li.slick-active button:before{
        width: 14px;
        background-color: #C9F44C;
      }
    }
  }
  .main{
    background: #FFFFFF;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-top: -25px;
    position: relative;
    padding: 16px 20px 30px;
    .title{
      font-family: Akrobat-ExtraBold;
      font-size: 24px;
      font-weight: normal;
      line-height: 29px;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 11px;
    }
    .count{
      .slog{
        font-family: 苹方-简;
        font-size: 10px;
        font-weight: normal;
        line-height: 12px;
        letter-spacing: 0px;
        color: #333333;
        margin-bottom: 8px;
      }
      .MuiLinearProgress-root{
        background: rgba(0, 0, 0, 0.9868);
        height: 8px;
        border-radius: 5px;
      }
      .MuiLinearProgress-bar{
        background: #B7E82C;
        border-radius: 5px;
      }
      .progress-count{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;
        >div{
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          &:first-child{
            align-items: flex-start;
          }
          .num{
            font-family: Akrobat-ExtraBold;
            font-size: 15px;
            font-weight: normal;
            line-height: 18px;
            letter-spacing: 0px;
            color: #000000;
          }
          p{
            font-family: 苹方-简;
            font-size: 10px;
            font-weight: normal;
            line-height: 12px;
            letter-spacing: 0px;
            color: #333333;
          }
        }
      }
      .quantity{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .Chance{
          border-radius: 12px;
          background: #EBF1FF;
          font-family: 苹方-简;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 0px;
          color: #525DD5;
          padding: 5px 6px;
          margin-right: 27px;
          span{
            font-family: Akrobat-ExtraBold;
            font-weight: 400;
            font-size: 12;
          }
        }
        .box{
          display: flex;
          align-items: center;
          justify-content: center;
          >span{
            font-family: 苹方-简;
            font-size: 10px;
            font-weight: normal;
            line-height: 12px;
            letter-spacing: 0px;
            color: #333333;
            margin-left: 5px;
          }
        }
      }
      .btn{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 分为三列 */
        gap: 10px; /* 间距为 14px */
        .join{
          border-radius: 16px;
          background: #C9F44C;
          font-family: 苹方-简;
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0px;
          color: #000000;
        }
        .add{
          border-radius: 16px;
          background: #000000;
          font-family: 苹方-简;
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0px;
          color: #C9F44C;
        }
      }
    }
  }
}